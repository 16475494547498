import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import qs from 'qs'
Vue.prototype.$qs = qs
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$videos = Video
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer);
Vue.prototype.$screenWidth = window.screen.width
import './plugins/bootstrap-vue'
import { NavbarPlugin } from 'bootstrap-vue'
Vue.use(NavbarPlugin)


axios.defaults.baseURL = 'https://www.xnrun.com/xnrun'
// axios.defaults.baseURL = 'http://192.168.3.121:8081'
Vue.prototype.$http = axios

Vue.config.productionTip = false

axios.interceptors.request.use(config => {
  if(config.url == "/official/login/doLogin" || config.url == "/official/user/modifyPassword"){
    return config
  }else{
    config.headers.token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token')
    return config
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
// // 导入字体图标
// import './assets/fonts/iconfont.css'
// // 导入全局样式表
// import './assets/css/global.css'
// import TreeTable from 'vue-table-with-tree-grid'

// // 导入富文本编辑器
// import VueQuillEditor from 'vue-quill-editor'

// // 导入 NProgress 包对应的JS和CSS
// import NProgress from 'nprogress'

// 配置请求的跟路径
// // 在 request 拦截器中，展示进度条 NProgress.start()
// axios.interceptors.request.use(config => {
//   // console.log(config)
//   NProgress.start()
//   config.headers.Authorization = window.sessionStorage.getItem('token')
//   // 在最后必须 return config
//   return config
// })
// // 在 response 拦截器中，隐藏进度条 NProgress.done()
// axios.interceptors.response.use(config => {
//   NProgress.done()
//   return config
// })


// Vue.component('tree-table', TreeTable)
// // 将富文本编辑器，注册为全局可用的组件
// Vue.use(VueQuillEditor)

// Vue.filter('dateFormat', function(originVal) {
//   const dt = new Date(originVal)

//   const y = dt.getFullYear()
//   const m = (dt.getMonth() + 1 + '').padStart(2, '0')
//   const d = (dt.getDate() + '').padStart(2, '0')

//   const hh = (dt.getHours() + '').padStart(2, '0')
//   const mm = (dt.getMinutes() + '').padStart(2, '0')
//   const ss = (dt.getSeconds() + '').padStart(2, '0')

//   return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
// })
