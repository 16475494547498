<template>
  <div id="app">
      <vue-particles
        color="#fff"
        :particleOpacity="0.7"
        :particlesNumber="60"
        shapeType="circle"
        :particleSize="4"
        linesColor="#fff"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="2"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
        class="lizi"
      >
      </vue-particles>
    <Header v-if="!isAlone"></Header>
    <router-view class="content"></router-view>
    <Footer v-if="!isAlone"></Footer>

    <!-- 客服 -->
<!--   <el-popover
      placement="top"
      trigger="click">
      <img src="https://abc.xnrun.com/image/default/519DE61B294E4EEFA11E74911F243C60-6-2.png" alt="">
      <div class="service-inner">
        <i class="iconfont">&#xe635;</i>19128616049
      </div>
      <i class="iconfont service" slot="reference">&#xe646;</i>
    </el-popover> -->

      <el-popover v-if="isAlonecoach!='/coach'" placement="bottom-start" style="top:6.25rem;" >
          <img @click="got()" class="service2"  slot="reference" src="https://abc.xnrun.com/image/default/A9FDCC618B094EBB97774B44001AFA04-6-2.png"/>
      </el-popover>

  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'app',
  components: {
    Footer,
		Header
  },
  computed: {
      isAlone(){
        console.log(this.$route)
        return this.$route.meta.isAlone
      },
      isAlonecoach(){
        console.log(this.$route)
        return this.$route.fullPath
      }
  },
  methods:{
    got(){
      this.$router.push({
        path: '/coach'
      })
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'iconfont';  /* project id 2426134 */
  src: url('//at.alicdn.com/t/font_2426134_uab8h5mpvgi.eot');
  src: url('//at.alicdn.com/t/font_2426134_uab8h5mpvgi.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_2426134_uab8h5mpvgi.woff2') format('woff2'),
  url('//at.alicdn.com/t/font_2426134_uab8h5mpvgi.woff') format('woff'),
  url('//at.alicdn.com/t/font_2426134_uab8h5mpvgi.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_2426134_uab8h5mpvgi.svg#iconfont') format('svg');
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}
body{
  margin: 0;
  /* min-width: 1440px; */
}
// *{
//   &::-webkit-scrollbar
//   {
//     width: 0;
//     background-color: rgba($color: #000000, $alpha: 0);
//   }
//   scrollbar-width: none;
//   scrollbar-color: #e3e3e3 transparent;;
// }
.content{
  /* padding-top: 104px; */
}

@media only screen and (max-width: 1200px) {
  .service{
    position: fixed;
    right: 5vw;
    bottom: 5vh;
    background-color: skyblue;
    font-size: 3rem;
    color: white;
    border-radius: 2rem;
    display: block;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    box-shadow: 0 0 1rem .1rem skyblue;
    cursor: pointer;
    user-select: none;
    z-index: 2021;
    &:hover{
      box-shadow: 0 0 1rem .5rem skyblue;
      transition-duration: .3s;
    }
  }
  .service2{
    border-radius: 0;width: 30%;height: 15%;top: 50%;right: 0;
    position: fixed;
    bottom: 5vh;
    // background-color: skyblue;
    font-size: 3rem;
    color: white;
    display: block;
    line-height: 4rem;
    // box-shadow: 0 0 1rem .1rem skyblue;
    cursor: pointer;
    user-select: none;
    z-index: 999;
    &:hover{
      width: 32%;height: 18%;
      transition-duration: .3s;
    }
  }
  .el-popover{
    img{
      width: 90vw;
    }
  }
  .service-inner{
    text-align: center;
    height: 3rem;
    line-height: 3rem;
    font-size: 2rem;
    .iconfont{
      display: inline-block;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      border-radius: 1.5rem;
      color: white;
      font-size: 2rem;
      background-color: deepskyblue;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .service{
    position: fixed;
    right: 5vw;
    bottom: 5vh;
    background-color: skyblue;
    font-size: 7rem;
    color: white;
    border-radius: 4rem;
    display: block;
    width: 8rem;
    height: 8rem;
    line-height: 8rem;
    box-shadow: 0 0 1rem skyblue;
    cursor: pointer;
    user-select: none;
    z-index: 2021;
    &:hover{
      box-shadow: 0 0 1rem .5rem skyblue;
      transition-duration: .3s;
    }
  }
  .service2{
      border-radius: 0;width: 10%;height: 20%;top: 50%;right: 0;
    position: fixed;
    // background-color: skyblue;
    font-size: 7rem;
    color: white;
    display: block;
    line-height: 8rem;
    // box-shadow: 0 0 1rem skyblue;
    cursor: pointer;
    user-select: none;
    z-index: 999;
    &:hover{
      width: 12%;height: 22%;
      transition-duration: .3s;
    }
  }
  .service-inner{
    text-align: center;
    height: 3rem;
    line-height: 3rem;
    font-size: 2rem;
    .iconfont{
      display: inline-block;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      border-radius: 1.5rem;
      color: white;
      font-size: 2rem;
      background-color: deepskyblue;
    }
  }
}

</style>
