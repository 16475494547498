<template>
	<div class="header">
		<b-navbar toggleable="lg">
			<b-navbar-brand href="#" to="/">
				<img src="https://abc.xnrun.com/image/default/F9492B6E03CE469CBEA17A65F846E7B5-6-2.png" alt="logo"
					width="200px">
			</b-navbar-brand>
			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
			<b-collapse id="nav-collapse" is-nav>
				<b-navbar-nav>
					<b-nav-item v-for="(item,index) in menu" :key="index" :index="item.path" :to="item.path"
						@click="index==4?onEnterTeam2(item.path):''">{{item.title}}
					</b-nav-item>
				</b-navbar-nav>
				<!-- Right aligned nav items -->
				<b-navbar-nav v-if="token" class="ml-auto">
					<b-nav-item-dropdown right>
						<!-- Using 'button-content' slot -->
						<template v-slot:button-content>
							<div class="header_right">
								<img src="../assets/imgs/btx1.png" alt="">
								<img v-if="!isVip" src="../assets/imgs/header/vip1.png" alt="">
								<span>{{username}}</span>
							</div>
							<!-- <em>User</em> -->
						</template>
						<b-dropdown-item href="#">
							<b-nav-item :to="'/personal/vip'" class="vip">VIP 权限</b-nav-item>
						</b-dropdown-item>
						<b-dropdown-item href="#">
							<b-nav-item :to="'/personal/course'">我的活动</b-nav-item>
						</b-dropdown-item>
						<b-dropdown-item href="#">
							<b-nav-item :to="'/personal/info'">个人信息</b-nav-item>
						</b-dropdown-item>
						<b-dropdown-item href="#">
							<b-nav-item @click="logout">退出登录</b-nav-item>
						</b-dropdown-item>
					</b-nav-item-dropdown>
				</b-navbar-nav>
				<!-- <b-navbar-nav v-else class="ml-auto header_right" @click="showLogin = true">登录</b-navbar-nav> -->
			</b-collapse>
		</b-navbar>
		<Login v-if="showLogin" @close="showLogin = false"></Login>
	</div>

</template>

<script>
	import Login from './Login.vue'
	export default {
		components: {
			Login
		},
		data() {
			return {
				visible: false,
				activeIndex: window.sessionStorage.menu,
				routeType: window.sessionStorage.routeType,
				isLogin: false,
				isVip: false,
				menu: [{
						title: '首页',
						path: '/'
					},
					{
						title: '技术分析文章',
						path: '/Journalism'
					},
					{
						title: '活动',
						path: '/course'
					},
					{
						title: '业务',
						path: '/business'
					},
					// {
					// 	title: '学员动态圈',
					// 	path: 'https://xnsq.xnrun.com'
					// },
					{
						title: '小聂是谁',
						path: '/coach'
					},
					{
						title: '基地',
						path: '/base'
					},
					{
						title: 'APP下载',
						path: '/download'
					}
				],
				showLogin: false
			}
		},

		computed: {
			username() {
				return window.localStorage.getItem('username') || window.sessionStorage.getItem('username')
			},
			token() {
				return window.localStorage.getItem('token') || window.sessionStorage.getItem('token')
			},

		},
		methods: {
			onEnterTeam2(item) {
				window.open(item, "_blank");
			},
			logout() {
				this.$confirm('确定要退出?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const {
						data
					} = await this.$http.post('/official/login/logout')
					if (data.code == 200 && data.msg == "用户退出成功") {
						window.localStorage.removeItem('username')
						window.localStorage.removeItem('token')
						this.$router.go(0)
					}

				});
			}

		},
		mounted() {

		}
	}
</script>

<style scoped lang="scss">
	.header {
		padding: 1%;
		z-index: 99;
		background-color: #fff;
		vertical-align: middle;
		display: inline-block;
		border-bottom: 1px solid #eee;
		width: 100%;
		box-sizing: border-box;

		::v-deep .navbar-nav {
			font-size: 1.2em;
			margin: 0 auto;
		}

		::v-deep .navbar-nav.ml-auto {
			margin: 0 !important;
		}

		::v-deep .navbar-light .navbar-nav .nav-link {
			padding: 0 40px;
			line-height: 60px;
			color: #909399;
			white-space: nowrap;
		}

		::v-deep .navbar-toggler.collapsed {
			border: 0;
		}

		::v-deep .navbar-light .navbar-toggler {
			border: 0;
		}

		.header_right {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			color: #909399;
			font-size: 1.2em;
			float: right;

			&:hover {
				cursor: pointer;
			}

			img {
				width: 60px;
				height: 60px;
				border-radius: 30px;
				box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
				border: 1px solid #ebeef5;
				margin-right: 20px;
				box-sizing: border-box;

				&:nth-of-type(2) {
					width: 30px;
					height: 30px;
					position: absolute;
					left: 40px;
					top: 40px;
					border: none;
				}
			}

			span {
				line-height: 60px;
			}
		}

		::v-deep .dropdown-toggle::after {
			display: none;
		}

		::v-deep .dropdown-menu {
			.dropdown-item {
				text-align: center;

				li {
					a {
						color: #222;
						font-size: 21px;
					}

				}
			}

			.vip {
				a {
					background: linear-gradient(to right, #F7503F, #FAC340);
					-webkit-background-clip: text;
					color: transparent !important;
					font-weight: bolder;
				}
			}
		}

		::v-deep .nav-link.router-link-exact-active.router-link-active {
			border-bottom: 2px #F7503F solid;
			background: linear-gradient(#F7503F, #FAC340);
			-webkit-background-clip: text;
			color: transparent;
		}
	}
</style>
