import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{index:1,title: '小聂跑法'},
    component: Home
  },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  {
    path: '/about',
    name: 'About',
    meta: {
      type: 'nav'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/download',
    name: 'Download',
    meta: {
        index: 1,title:'下载'
    },
    component: () => import(/* webpackChunkName: "download" */ '../views/Download.vue')
  },
  {
    path: '/course',
    name: 'Course',
    meta: {
      index: 1,title:'小聂简介'
    },
    component: () => import(/* webpackChunkName: "course" */ '../views/course/index.vue'),
  },
  {
    path: '/base',
    name: 'base',
    meta: {
      index: 1,title:'生态基地'
    },
    component: () => import(/* webpackChunkName: "course" */ '../views/base/index.vue'),
  },
	{
	  path: '/baseInfo',
	  name: 'baseInfo',
	  meta: {
	    index: 1,title:'生态基地'
	  },
	  component: () => import(/* webpackChunkName: "course" */ '../views/base/baseInfo.vue'),
	},
  {
    path: '/course/video',
    name: '/course/video',
    component: () => import('../views/course/video.vue')
  },
  {
    path: '/photowall',
    name: 'PhotoWall',
    meta: {
      index: 1,title:'荣誉榜'
    },
    component: () => import(/* webpackChunkName: "photowall" */ '../views/PhotoWall/PhotoWall.vue')
  },
  {
    path: '/photowalldetail',
    name: 'PhotoWallDetail',
    component: () => import(/* webpackChunkName: "photowalldetail" */ '../views/PhotoWall/PhotoWallDetail.vue')
  },
  {
    path: '/corporateCulture',
    name: 'corporateCulture',
    component: () => import(/* webpackChunkName: "photowalldetail" */ '../views/corporateCulture/corporateCulture.vue')
  },
  {
    path: '/Journalism',//新闻
    name: 'Journalism',
    meta: {
      type: 'nav',title: '文章列表'
    },
    component: () => import(/* webpackChunkName: "photowall" */ '../views/Journalism/index.vue')
  },
  {
    path: '/article',//新闻详情
    name: 'article',
    meta:{index:1,title: '文章详情'},
    component: () => import(/* webpackChunkName: "photowall" */ '../views/Journalism/article.vue')
  },
  {
    path: '/Notice',//新闻详情
    name: 'Notice',
    component: () => import(/* webpackChunkName: "photowall" */ '../views/Journalism/Notice.vue')
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import(/* webpackChunkName: "personal" */ '../views/Personal/Personal.vue'),
    children:[
      {
        path: 'info',
        name: 'Info',
        component: () => import('../views/Personal/Info.vue')
      },
      {
        path: 'course',
        name: 'personal/course',
        meta: {
          index: 1,title:'小聂简介'
        },
        component: () => import('../views/Personal/Course.vue')
      },
      {
        path: 'vip',
        name: 'Vip',
        component: () => import('../views/Personal/Vip.vue')
      }
    ]
  },
  {
    path: '/personal2',
    component: () => import(/* webpackChunkName: "personal" */ '../views/Personal/index.vue'),
  },
  {
    path: '/share',// 微信分享
    component: () => import(/* webpackChunkName: "share" */ '../views/share/index.vue'),
    meta: {
      isAlone: true
    }
  },
  {
    path: '/share/download',// 微信分享
    component: () => import(/* webpackChunkName: "share" */ '../views/share/download.vue'),
    meta: {
      isAlone: true
    }
  },
  {
    path: '/share/personal',// 微信分享
    component: () => import(/* webpackChunkName: "share" */ '../views/share/personal.vue'),
    meta: {
      isAlone: true
    }
  },
  {
    path: '/curriculum',// 微信分享
    component: () => import(/* webpackChunkName: "share" */ '../views/share/curriculum.vue'),
    meta: {
      isAlone: true
    }
  },
  {
    path: '/coach',
    meta: {
      index: 1,title:'小聂简介'
    },
    component: () => import(/* webpackChunkName: "coach" */ '../views/coach/index.vue')
  },
  {
    path: '/business',

    component: () => import(/* webpackChunkName: "business" */ '../views/business/index.vue'),
    meta: {
      index: 1,title:'业务'
    },
  },
  {
    path: '/run',
    component: () => import(/* webpackChunkName: "run" */ '../views/run/index.vue'),
    meta: {
      index: 1,title:'战队详情'
    },
  },
  {
    path: '/protocol',//
    component: () => import(/* webpackChunkName: "protocol" */ '../views/protocol.vue'),
    meta: {
      isAlone: true
    }
  },
	{
	  path: '/3Dmap',//
	  component: () => import(/* webpackChunkName: "protocol" */ '../views/base/3Dmap.vue'),
	  meta: {
	    isAlone: true
	  }
	},
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

router.beforeEach((to,from,next) => {
  console.log(to)
  window.sessionStorage.setItem('routeType',to.meta.title)
  window.sessionStorage.menu = to.fullPath
  if (to.meta.title) {
      document.title = to.meta.title
    }
  next()
})

export default router
