<template>
	<div class="home">
		<el-carousel :interval="5000" arrow="always" height="30vw">
			<el-carousel-item v-for="(item,index) in carousel" :key="index">
				<el-image :src="item.imgurl" fit="cover"></el-image>
			</el-carousel-item>
		</el-carousel>

		<div class="intro">
			<div class="item" v-for="(item,index) in intros" :key="index">
				<img :src="item.img" alt="">
				<h2>{{item.title}}</h2>
				<p style="color: #6f6d6d;line-height: 1.5;"> {{item.content}}</p>
			</div>
		</div>

		<!--时间线-->
		<!-- <div class="timeLine" style="overflow: hidden;">
            <div class="content">
                <p class="tit">{{timeLineList[timeIndex].year}}</p>
                <p>{{timeLineList[timeIndex].info}}</p>
            </div>
            <div class="my_timeline_prev" @click="moveLeft">
                <img src="../assets/imgs/home/1.png" class="my_timeline_node"/>
                <div class="my_timeline_item_line" style="margin-top: -18px;"></div>
                <div class="my_timeline_item_content" style="color: rgba(0,0,0,0);">上</div>
            </div>
            <div class="ul_box">
                <ul class="my_timeline" ref="mytimeline" style="margin-left: 10px;">
                    <li class="my_timeline_item" v-for="(item,index) in timeLineList" :key="index">
                        <div class="my_timeline_node" :style = " {backgroundColor: item.bgcolor, width: item.size + 'px', height: item.size + 'px'}" @click="changeActive(index)" :class="{active: index == timeIndex}"></div>
                        <div class="my_timeline_item_line"></div>
                        <div class="my_timeline_item_content" :style="{color: item.color, fontSize: item.fontsize + 'px'}">
                            {{item.timestamp}}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="my_timeline_next" @click="moveRight">
                <img src="../assets/imgs/home/1.png" class="my_timeline_node"/>
                <div class="my_timeline_item_content" style="color: rgba(0,0,0,0);">下</div>
            </div>
        </div> -->

		<div class="team">
			<h1>训练营各地战队</h1>
			<lory class="items" :options="{ enableMouseEvents: true}" v-if="team.length>0">
				<item class="item" v-for="(item,index) in team" :key="index">
					<img :src="item.showImage" alt="">
					<h2>{{item.name}}</h2>
					<!-- <p>队员数量：{{item.memberNum}}</p> -->
					<el-button type="info" @click="onEnterTeam(item.address)">进入战队</el-button>
				</item>
				<item class="item">
				</item>
				<prev slot="actions"></prev>
				<next slot="actions"></next>
			</lory>
		</div>

		<div class="member">
			<div class="items" v-for="(item,index) in members" :key="index">
				<h1 style="font-size: 34px;color: #505050;">{{item.title}}</h1>
				<div class="item" v-for="(item2,index2) in item.data" :key="item2.uuid"
					:style="{flexDirection:index2%2 === 0 || index2===0 ? 'row' : 'row-reverse'}">
					<img class="personalImage" :src="item2.personalImage" alt="">
					<div class="rightORleft">
						<h2>{{item2.name}}</h2>
						<p>出生年月：{{item2.born}}</p>
						<p>学员编号：{{item2.studentNum}}</p>
						<p>战队编号：{{item2.teamNum}}</p>
						<p>相关经历入营时间：{{item2.joion}}</p>
						<el-button @click="onMore(item2.uuid,item2.teamId)">了解更多<i
								class="el-icon-arrow-right el-icon--right"></i>
						</el-button>
					</div>
				</div>
			</div>
			<div @click="goxyq"><img style="width: 20%;"
					src="https://abc.xnrun.com/image/default/19BEA13AF4EA4D9B8C9A79EAEAC560B2-6-2.png"></div>
		</div>

		<div class="jcspys" style="margin-top: 3%;">精彩视频</div>
		<div class="section44">
			<div class="wrapper">
				<video-player class="vjs-custom-skin" :options="playerOptions"></video-player>
			</div>
			<div class="wrapper">
				<div class="content">
					<div class="item" v-for="(item,index) in videoData" :key="index"
						@click="getVidoe(item.playerOptions)">
						<img :src="item.image" />
						<div class="htmltext">{{item.title}}
							<div class="fwbys">{{item.introduction}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<h1>学员分布图（仅供参考）</h1>
		<div id="china_map_box">
			<div id="china_map"></div>
		</div>
		<!-- <div style="height: 500px;width: 100%;margin: 2% 0;">
        <div id="main" style="width:50%;height: 500px;float: left;"></div>

        <div id="main2" style="width:50%;height: 500px;float: left;"></div>

      </div> -->
		<div id="main3" style="width:100%;height: 800px;"></div>

		<div class="toTop" @click="onTop()" v-if="showTop">
			返回顶部👆
		</div>
	</div>
</template>

<script>
	import echarts from "echarts";
	import '../js/china.js'
	import {
		Lory,
		Item,
		Prev,
		Next
	} from 'vue-lory'
	export default {
		components: {
			Lory,
			Item,
			Prev,
			Next
		},
		data() {
			return {


				timeIndex: 2,
				timeLineList: [{
					timestamp: '2012年',
					color: '#999',
					fontsize: 18,
					size: '28',
					bgcolor: '#e4e7ed',
					icon: 'el-iconprev',
					year: '2012',
					info: 'chengli'
				}, {
					timestamp: '2013年',
					color: '#999',
					fontsize: 18,
					size: '28',
					bgcolor: '#e4e7ed',
					year: '2013',
					info: '工作室更名为：西安拓美网络科技有限公司'
				}, {
					timestamp: '2014年',
					color: '#999',
					fontsize: 18,
					size: '28',
					bgcolor: '#e4e7ed',
					year: '2014',
					info: '工作室更名为：西安拓美网络科技有限公司'
				}, {
					timestamp: '2015年',
					color: '#999',
					fontsize: 18,
					year: '2015',
					size: '28',
					bgcolor: '#e4e7ed',
					info: '工作室更名为：西安拓美网络科技有限公司'
				}, {
					timestamp: '2016年',
					color: '#999',
					fontsize: 18,
					size: '28',
					year: '2016',
					bgcolor: '#e4e7ed',
					info: '工作室更名为：西安拓美网络科技有限公司'
				}, {
					timestamp: '2017年',
					color: '#999',
					fontsize: 18,
					size: '28',
					bgcolor: '#e4e7ed',
					year: '2017',
					info: '工作室更名为：西安拓美网络科技有限公司'
				}, {
					timestamp: '2018年',
					color: '#999',
					fontsize: 18,
					size: '28',
					bgcolor: '#e4e7ed',
					year: '2018',
					info: '工作室更名为：西安拓美网络科技有限公司'
				}, {
					timestamp: '2019年',
					color: '#999',
					fontsize: 18,
					year: '2019',
					bgcolor: '#e4e7ed',
					size: '28',
					info: '工作室更名为：西安拓美网络科技有限公司'
				}],





				carousel: [],
				intros: [{
						img: 'https://abc.xnrun.com/image/default/47EC60FC23744E149D101275AC224D8F-6-2.png',
						title: '针对性教学',
						content: '  独有的因地制宜、因人而异的训练方式，非常高效的练习反馈指导和提供精准的解决方法。'
					},
					{
						img: 'https://abc.xnrun.com/image/default/DE90C562151B41399816AA74134E28DE-6-2.png',
						title: '终身制模式',
						content: '  进群享受终身伴学答疑服务：每一名进入学员训练群的学员，终身享受训练反馈的指导和解决方案。'
					},
					{
						img: 'https://abc.xnrun.com/image/default/25CEEFD0AE43444FB91427B173245E90-6-2.png',
						title: '反馈定制教程',
						content: '  因材施教针对每个学员的特点进行教学，制定训练计划：训练营会根据每一名学员的个人身体条件特点，家庭和工作的影响，以及结合各地实际，目标比赛安排来制定每个人专属的训练方案和计划。'
					},
					{
						img: 'https://abc.xnrun.com/image/default/E11A74F4F5B24B3AAB22660DF07EAD91-6-2.png',
						title: '循序式细化学习',
						content: '  细化学习时间，循序渐进，适时总结分析纠错：不急不躁，以健康为本，快乐无伤，以享受跑步为目的，以良好的跑步运动习惯提高生活质量。'
					},
				],
				team: [],
				members: [{
						title: '助理教练',
						data: []
					},
					{
						title: '学员风采',
						data: []
					},
				],
				//echart data
				dataList: [{
						name: "南海诸岛",
						"chuji": 0,
						"teji": 0,
						"gaoji": 0,
						"zhongji": 0,
						value: 0,

						eventTotal: 100,
						specialImportant: 10,
						import: 10,
						compare: 10,
						common: 40,
						specail: 20
					},
					{
						name: "北京",
						"chuji": 10,
						"zhongji": 5,
						"teji": 0,
						"gaoji": 1,
						value: 16
					},
					{
						name: "天津",
						"初级": 12,
						"特级": 1,
						"zhongji": 0,
						"中级": 1,
						value: 0
					},
					{
						name: "上海",
						"chuji": 10,
						"teji": 0,
						"zhongji": 2,
						"gaoji": 2,
						value: 14
					},
					{
						name: "重庆",
						"chuji": 0,
						"teji": 0,
						"zhongji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "河北",
						"chuji": 6,
						"teji": 0,
						"zhongji": 2,
						"gaoji": 2,
						value: 10
					},
					{
						name: "河南",
						"chuji": 8,
						"zhongji": 2,
						"teji": 0,
						"gaoji": 1,
						value: 11
					},
					{
						name: "云南",
						"chuji": 15,
						"zhongji": 5,
						"teji": 0,
						"gaoji": 5,
						value: 24
					},
					{
						name: "辽宁",
						"chuji": 19,
						"zhongji": 12,
						"teji": 0,
						"gaoji": 11,
						value: 42
					},
					{
						name: "黑龙江",
						"chuji": 0,
						"zhongji": 0,
						"teji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "湖南",
						"chuji": 0,
						"zhongji": 0,
						"teji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "安徽",
						"chuji": 0,
						"zhongji": 0,
						"teji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "山东",
						"chuji": 3,
						"zhongji": 1,
						"teji": 0,
						"gaoji": 1,
						value: 5
					},
					{
						name: "新疆",
						"chuji": 5,
						"zhongji": 2,
						"teji": 0,
						"gaoji": 3,
						value: 10
					},
					{
						name: "江苏",
						"chuji": 0,
						"zhongji": 0,
						"teji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "浙江",
						"chuji": 0,
						"zhongji": 0,
						"teji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "江西",
						"chuji": 20,
						"zhongji": 12,
						"teji": 0,
						"gaoji": 10,
						value: 42
					},
					{
						name: "湖北",
						"chuji": 20,
						"zhongji": 6,
						"teji": 0,
						"gaoji": 2,
						value: 28
					},
					{
						name: "广西",
						"chuji": 0,
						"zhongji": 4,
						"teji": 0,
						"gaoji": 0,
						value: 4
					},
					{
						name: "甘肃",
						"chuji": 0,
						"zhongji": 0,
						"teji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "山西",
						"chuji": 5,
						"zhongji": 2,
						"teji": 0,
						"gaoji": 1,
						value: 8
					},
					{
						name: "内蒙古",
						"chuji": 0,
						"zhongji": 0,
						"teji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "陕西",
						"chuji": 0,
						"zhongji": 0,
						"teji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "吉林",
						"chuji": 0,
						"zhongji": 0,
						"teji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "福建",
						"chuji": 18,
						"zhongji": 4,
						"teji": 0,
						"gaoji": 0,
						value: 22
					},
					{
						name: "贵州",
						"chuji": 0,
						"zhongji": 0,
						"teji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "广东",
						"chuji": 60,
						"zhongji": 20,
						"teji": 10,
						"gaoji": 10,
						value: 92
					},
					{
						name: "青海",
						"chuji": 0,
						"zhongji": 0,
						"teji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "西藏",
						"chuji": 0,
						"teji": 0,
						"zhongji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "四川",
						"chuji": 5,
						"zhongji": 3,
						"teji": 0,
						"gaoji": 0,
						value: 8
					},
					{
						name: "宁夏",
						"chuji": 10,
						"zhongji": 3,
						"teji": 0,
						"gaoji": 2,
						value: 15
					},
					{
						name: "海南",
						"chuji": 0,
						"teji": 0,
						"zhongji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "台湾",
						"chuji": 0,
						"zhongji": 0,
						"teji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "香港",
						"chuji": 0,
						"teji": 0,
						"zhongji": 0,
						"gaoji": 0,
						value: 0
					},
					{
						name: "澳门",
						"chuji": 0,
						"zhongji": 0,
						"teji": 0,
						"gaoji": 0,
						value: 0
					}
				],
				option3: {
					title: {
						text: '学员数量变化',
						subtext: '仅供参考'
					},
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['学员总数', '新增学员数量']
					},
					toolbox: {
						show: true,
						feature: {
							dataZoom: {
								yAxisIndex: 'none'
							},
							dataView: {
								readOnly: false
							},
							magicType: {
								type: ['line', 'bar']
							},
							restore: {},
							saveAsImage: {}
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: ['2017', '2018', '2019', '2020', '2021']
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value} 人'
						}
					},
					series: [{
							name: '新增学员数量',
							type: 'line',
							data: [0, 112, 247, 269, 101],
							// markPoint: {
							//     data: [
							//         {type: 'max', name: '最大值'},
							//         {type: 'min', name: '最小值'}
							//     ]
							// },
							markLine: {
								data: [{
									type: 'average',
									name: '平均值'
								}]
							}
						},
						{
							name: '学员总数',
							type: 'line',
							data: [0, 112, 359, 628, 729],
							// markPoint: {
							//     data: [
							//         {name: '周最低', value: -2, xAxis: 1, yAxis: -1.5}
							//     ]
							// },
							markLine: {
								data: [
									// {type: 'average', name: '平均值'},
									[{
										symbol: 'none',
										x: '90%',
										yAxis: 'max'
									}, {
										symbol: 'circle',
										label: {
											position: 'start',
											formatter: '最大值'
										},
										type: 'max',
										name: '最高点'
									}]
								]
							}
						}
					]
				},
				option2: {
					title: {
						text: '学员等级数据图',
						subtext: '仅供参考',
						top: 10,
						left: 10
					},
					tooltip: {
						trigger: 'item'
					},
					legend: {
						type: 'scroll',
						bottom: 10,
						data: (function() {
							var list = [];
							for (var i = 1; i <= 1; i++) {
								list.push(i + 2020 + '');
							}
							console.log('书记0000000', list)
							return list;
						})()
					},
					visualMap: {
						top: 'middle',
						right: 0,
						color: ['red', 'orange'],
						calculable: false
					},
					radar: {
						indicator: [{
								text: '初级',
								max: 80
							},
							{
								text: '中级',
								max: 80
							},
							{
								text: '高级',
								max: 80
							},
							{
								text: '特级',
								max: 80
							},
							{
								text: '官方教练',
								max: 80
							}
						]
					},
					series: (function() {
						var series = [];
						for (var i = 1; i <= 1; i++) {
							series.push({
								name: '学员等级数据图（仅供参考）',
								type: 'radar',
								symbol: 'none',
								lineStyle: {
									width: 1
								},
								emphasis: {
									areaStyle: {
										color: 'rgba(0,250,0,0.3)'
									}
								},
								data: [{
									value: [
										75, 57, 58, 50, 2
									],
									name: i + 2020 + ''
								}]
							});
						}
						return series;
					})()
				},


				option: {
					title: {
						text: '比列图',
						subtext: '仅供参考',
						left: 'left'
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} ({d}%)'
					},
					legend: {
						left: 'center',
						top: 'bottom',
						data: ['rose1', 'rose2', 'rose3', 'rose4', 'rose5', 'rose6', 'rose7', 'rose8']
					},
					toolbox: {
						show: false,
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: false
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					series: [
						// {
						//     name: '男女比列',
						//     type: 'pie',
						//     radius: [20, 140],
						//     center: ['25%', '50%'],
						//     roseType: 'radius',
						//     itemStyle: {
						//       borderRadius: 5
						//     },
						//     label: {
						//       show: false
						//     },
						//     emphasis: {
						//       label: {
						//         show: true
						//       }
						//     },
						//     data: [{
						//         value: 92,
						//         name: '广东'
						//       },
						//       {
						//         value: 42,
						//         name: '江西'
						//       },
						//       {
						//         value: 42,
						//         name: '辽宁'
						//       },
						//       {
						//         value: 28,
						//         name: '湖北'
						//       },
						//       {
						//         value: 22,
						//         name: '福建'
						//       },
						//       {
						//         value: 16,
						//         name: '北京'
						//       },
						//       {
						//         value: 14,
						//         name: '上海'
						//       },
						//       {
						//         value: 11,
						//         name: '河南'
						//       },
						//       {
						//         value: 5,
						//         name: '山东'
						//       },
						//       {
						//         value: 10,
						//         name: '山东'
						//       },
						//       {
						//         value: 8,
						//         name: '山西'
						//       },
						//       {
						//         value: 15,
						//         name: '宁夏'
						//       }
						//     ]
						//   },
						{
							name: '地区占比',
							type: 'pie',
							radius: [40, 200],
							center: ['50%', '50%'],
							roseType: 'area',
							itemStyle: {
								borderRadius: 8
							},
							data: [{
									value: 92,
									name: '广东'
								},
								{
									value: 4,
									name: '广西'
								},
								{
									value: 42,
									name: '江西'
								},
								{
									value: 42,
									name: '辽宁'
								},
								{
									value: 28,
									name: '湖北'
								},
								{
									value: 22,
									name: '福建'
								},
								{
									value: 16,
									name: '北京'
								},
								{
									value: 14,
									name: '上海'
								},
								{
									value: 11,
									name: '河南'
								},
								{
									value: 5,
									name: '山东'
								},
								{
									value: 10,
									name: '山东'
								},
								{
									value: 8,
									name: '山西'
								},
								{
									value: 15,
									name: '宁夏'
								},
								{
									value: 24,
									name: '云南'
								}, {
									name: "新疆",
									value: 10
								},
								{
									name: "四川",
									value: 8
								}

							]
						},
					]
				},
				//echart 配制option
				options: {
					tooltip: {
						triggerOn: "mousemove", //mousemove、click
						padding: 8,
						borderWidth: 1,
						borderColor: '#ffaa00',
						backgroundColor: 'rgba(255,255,255,0.8)',
						textStyle: {
							color: '#000000',
							fontSize: 13
						},
						formatter: function(e, t, n) {
							console.log(e)
							let data = e.data;
							//模拟数据
							// data.specialImportant = 0;
							// data.compare = 0;
							// data.common = 0;
							// data.specail = 0;

							let context = `
                          <div>
                              <p><b style="font-size:15px;">${data.name}</b>(学员等级数量)</p>
                              <p class="tooltip_style"><span class="tooltip_left">高级</span><span class="tooltip_right">${data.gaoji}</span></p>
                              <p class="tooltip_style"><span class="tooltip_left">中级</span><span class="tooltip_right">${data.zhongji}</span></p>
                              <p class="tooltip_style"><span class="tooltip_left">初级</span><span class="tooltip_right">${data.chuji}</span></p>
                          </div>
                       `
							return context;
						}
					},
					visualMap: {
						show: true,
						left: 500,
						bottom: 40,
						showLabel: true,
						pieces: [{
								gte: 5,
								lt: 20,
								label: "1~10",
								color: "#b50909"
							}, {
								gte: 5,
								lt: 10,
								label: "1~10",
								color: "#e70c0c"
							},
							{
								gte: 1,
								lt: 5,
								label: "1~5",
								color: "#fe2b2b"
							},
							{
								gte: 0,
								lt: 0,
								label: '0',
								color: "#f7ded9"
							}
						]
					},
					geo: {
						map: "china",
						scaleLimit: {
							min: 1,
							max: 2
						},
						zoom: 1,
						top: 120,
						label: {
							normal: {
								show: true,
								fontSize: "14",
								color: "rgba(0, 0, 0, 1.0)"
							}
						},
						itemStyle: {
							normal: {
								//shadowBlur: 50,
								//shadowColor: 'rgba(0, 0, 0, 0.2)',
								borderColor: "rgba(255, 255, 255, 0.6)"
							},
							emphasis: {
								areaColor: "#ffb217",
								shadowOffsetX: 0,
								shadowOffsetY: 0,
								borderWidth: 0
							}
						}
					},
					series: [{
						name: "突发事件",
						type: "map",
						geoIndex: 0,
						data: []
					}]
				},
				videoData: [],
				playerOptions: {
					playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
					autoplay: false, //如果true,浏览器准备好时开始回放。
					controls: true, //控制条
					preload: 'auto', //视频预加载
					muted: false, //默认情况下将会消除任何音频。
					loop: false, //导致视频一结束就重新开始。
					language: 'zh-CN',
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: 'video/mp4',
						//src: require("http://img.yopoo.cn/banner_video.mp4")
						src: 'https://abc.xnrun.com/customerTrans/f0d62f22b86796c02039a4785c29cc1b/4a64a1a-17844a46424-0006-ccae-1a2-b67f1.mp4' //你所放置的视频的地址，最好是放在服务器上
					}],
					//poster: "http://39.106.117.192:8080/static/indexImg.png", //你的封面地址（覆盖在视频上面的图片）
					width: document.documentElement.clientWidth,
					notSupportedMessage: '此视频暂无法播放，请稍后再试' //允许覆盖Video.js无法播放媒体源时显示的默认信息。
				},
				begin: '',
				timer: '',
				showTop: false
			}
		},
		created() {
			this.getCarousel()
			this.getTeam()
			this.getMembers()
			this.setEchartOption()
			this.getMaplist()
			this.getVideoList()
		},
		mounted() {
			window.addEventListener('scroll', this.scrollFunction);
			this.$nextTick(() => {
				this.initEchartMap();
				// this.drawPie("main");
				// this.drawPie("main2");
				this.drawPie("main3");
				if (this.begin) {
					return;
				}
				this.begin = true;
				// this.timer = setInterval(() => {
				//   this.autoPlay()
				// }, 3000)
			});
		},
		methods: {
			changeActive(index) {
				this.timeIndex = index;
			},
			moveLeft() {
				let marginLeft = parseInt(this.$refs.mytimeline.style.marginLeft);
				let listNum = 0;
				if (marginLeft <= 10 && (marginLeft >= -650)) {
					this.$refs.mytimeline.style.marginLeft = marginLeft - 220 + 'px';
				}
			},
			moveRight() {
				let marginLeft = parseInt(this.$refs.mytimeline.style.marginLeft);
				if (marginLeft < (-200)) {
					this.$refs.mytimeline.style.marginLeft = marginLeft + 220 + 'px';
				}
			},

			async getTeam() {
				let res = await this.$http.get('official/team/listTeam')
				console.log({
					team: res
				})
				this.team = res.data.data
			},

			async getMembers() {
				let res = await this.getMember('official/student/getHomePageData', {
					params: {
						type: 0
					}
				})
				let res2 = await this.getMember('official/student/getHomePageData', {
					params: {
						type: 1
					}
				})
				this.members[0].data = res
				this.members[1].data = res2
				console.log({
					members: this.members
				})
			},

			async getMember(path, param) {
				let res = await this.$http.get(path, param)
				res.data.data.forEach(item => {
					let intro = item.intro.split('</p><p>')
					intro.forEach(item2 => {
						if (item2.startsWith('出生年月：')) {
							item. ['born'] = item2.slice(5)
						}
						if (item2.startsWith('学员编号：')) {
							item. ['studentNum'] = item2.slice(5)
						}
						if (item2.startsWith('战队编号：')) {
							item. ['teamNum'] = item2.slice(5)
						}
						if (item2.startsWith('入营时间：')) {
							item. ['join'] = item2.slice(5)
						}
					})
				})
				return res.data.data
			},

			async getCarousel() {
				let res = await this.$http.get('slideshow/getSlideshow', {
					params: {
						slideshowType: '0'
					}
				})
				console.log({
					carousel: res
				})
				this.carousel = res.data.data
			},

			onMore(id, teamId) {
				this.$router.push({
					path: '/personal2',
					query: {
						id: id,
						teamId: teamId
					}
				})
			},
			goxyq() {
				window.open('/PhotoWall', "_blank");
			},
			go3D() {
				window.open('/3Dmap', "_blank");
			},
			onEnterTeam(item) {
				window.open(item, "_blank");
			},

			//初始化中国地图
			initEchartMap() {
				var that = this;
				let mapDiv = document.getElementById('china_map');
				let myChart = echarts.init(mapDiv);
				console.log(that.options.data);
				myChart.setOption(that.options);
			},
			//修改echart配制
			setEchartOption() {
				var that = this;
				// this.$http.get('/official/user/cityCount').then((res) => {
				// console.log('数据1', res.data.data);
				// var arr = res.data.data[0];
				//   for (var i = 0; i < arr.length; i++) {
				//     for (var j = i + 1; j < this.dataList.length; j++) {
				//       // var st = '\/' +this.dataList[j].name +'\/'
				//       var st = RegExp(this.dataList[j].name)
				//       if (st.test(arr[i].name)) {
				//         console.log(arr[i].chuji)
				//         this.dataList[j].chuji = arr[i].chuji == undefined ? 0 : arr[i].chuji
				//         this.dataList[j].zhongji = arr[i].zhongji == undefined ? 0 : arr[i].zhongji
				//         this.dataList[j].teji = arr[i].teji == undefined ? 0 : arr[i].teji
				//         this.dataList[j].gaoji = arr[i].gaoji == undefined ? 0 : arr[i].gaoji
				//       }
				//     }
				//   }
				//   console.log( this.dataList)
				console.log('xinde66666666', that.dataList[26])
				this.options.series[0]['data'] = that.dataList;
				// }).catch((res) => {



				// })





			},
			drawPie(id) {
				var that = this;
				that.charts = echarts.init(document.getElementById(id));
				if (id == "main2") {
					that.charts.setOption(that.option2);
				} else if (id == "main") {

					that.charts.setOption(that.option);
				} else {
					that.charts.setOption(that.option3);
				}
			},
			//获取地图信息
			async getMaplist() {
				var that = this;
				let res = await that.$http.get('/official/user/cityCount', {
					params: {}
				})
				console.log('map数据0000000000000000000000000', res.data.data);
				var arr = res.data.data[0];
				for (var i = 0; i < arr.length; i++) {
					for (var j = i + 1; j < that.dataList.length; j++) {
						// var st = '\/' +this.dataList[j].name +'\/'
						var st = RegExp(that.dataList[j].name)
						if (st.test(arr[i].name)) {
							console.log(arr[i])
							that.dataList[j].chuji = arr[i].chuji == undefined ? 0 : arr[i].chuji
							that.dataList[j].zhongji = arr[i].zhongji == undefined ? 0 : arr[i].zhongji
							that.dataList[j].teji = arr[i].teji == undefined ? 0 : arr[i].teji
							that.dataList[j].gaoji = arr[i].gaoji == undefined ? 0 : arr[i].gaoji
							that.dataList[j].value = arr[i].value == undefined ? 0 : arr[i].value
						}
					}
				}
				console.log('xinde 000000', that.dataList[26])
			},
			getVidoe(pv) {
				this.playerOptions = pv
			},
			//获取视频列表
			async getVideoList() {
				let res = await this.$http.get('/publicity/video/listByPage', {
					params: {
						pageNum: 1,
						pageSize: 10
					}
				})
				console.log('数据', res.data.data);

				console.log(res);
				res.data.data.forEach((element) => {
					let playerOptions = {
						playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
						autoplay: true, //如果true,浏览器准备好时开始回放。
						muted: false, // 默认情况下将会消除任何音频。
						loop: false, // 导致视频一结束就重新开始。
						preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
						language: "zh-CN",
						aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
						fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
						sources: [{
							src: element.video, // 路径
							type: "video/mp4", // 类型
						}, ],
						poster: " element.image", //你的封面地址
						width: document.documentElement.clientWidth,
						notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
						controlBar: {
							timeDivider: true,
							durationDisplay: true,
							remainingTimeDisplay: false,
							fullscreenToggle: true, //全屏按钮
						},
					};
					element.playerOptions = playerOptions
					console.log(element)
				});
				this.playerOptions = res.data.data. [0].playerOptions
				this.videoData = res.data.data;
				console.log('处理后数据', this.videoData);
			},

			onTop() {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			},

			scrollFunction() {
				if (document.body.scrollTop > 2000 || document.documentElement.scrollTop > 2000) {
					this.showTop = true
				} else {
					this.showTop = false
				}
			}
		}
	}
</script>
<style>
	#china_map .tooltip_style {
		line-height: 1.7;
		overflow: hidden;
	}

	#china_map .tooltip_left {
		float: left;
		color: #000000;
	}

	#china_map .tooltip_right {
		float: right;
		color: #000000;
	}
</style>
<style lang="scss" scoped="">
	img {
		object-fit: cover;
	}

	.section44 {
		border-radius: 6px;

		@media screen and (max-width: 1200px) {
			padding: 0;
			margin: 0;
			display: flex;
			flex-direction: column;
		}

		width: 100%;
		// padding: 0% 10% 2% 10%;
		// margin: 5vh 0;
		margin-bottom: 3%;
		display: flex;
		flex-wrap: nowrap;

		h1 {
			margin: 0;
		}

		.wrapper {
			@media screen and (max-width: 1200px) {
				width: 100% !important;
				height: unset;
			}

			width: 100%;
			padding: 1%;
			height: unset;
			color: #F9F9F9;
			background-color: #eee;

			img {
				border: 0;
				// padding: 0;
			}

			&:first-child {
				border-radius: 0 0 0 6px;

				@media screen and (min-width: 1200px) {
					background-color: rgba($color: #ff4d40, $alpha: .9);
					padding-left: 1%;
				}

				.title {
					background-color: rgba($color: #ff4d40, $alpha: .9);
				}

				.content {
					@media screen and (max-width: 1200px) {
						color: black;
					}

					.item {
						@media screen and (max-width: 768px) {
							display: flex;
							flex-direction: column;
							margin: 0;
						}

						.htmltext {
							width: 20%;
							font-weight: 800;

							@media screen and (max-width: 768px) {
								width: 100%;
								font-weight: 800;
							}

							.fwbys {
								font-size: 16px;

								@media screen and (max-width: 768px) {
									font-size: 12px;
								}
							}
						}

						display: flex;
						justify-content: space-between;
						margin: 2% 0;

						img {
							width: 80%;
							// height: 150px;
							flex-shrink: 0;

							@media screen and (max-width: 768px) {
								width: 100vw;
								height: 66.6666666vw;
							}
						}

						a {
							flex-grow: 1;
							text-align: left;
							padding: 2%;
						}

						.date {
							padding: 2% 0;
							flex-shrink: 0;
							align-self: flex-end;
						}
					}
				}
			}

			&:last-child {
				overflow-y: scroll;
				width: 45%;
				// height: 325px;
				border-radius: 0 0 6px 0;

				@media screen and (min-width: 1201px) {
					background-color: rgba($color: #ff4d40, $alpha: .9);
					// padding: 0 1%;

				}

				@media screen and (max-width: 768px) {
					width: 100% !important;
				}


				.title {
					position: relative;
					background-color: #ff2b18;
				}

				.content {

					@media screen and (max-width: 1200px) {
						color: black;
					}

					.item {
						background-color: #ffffff;
						color: #000000;

						@media screen and (max-width: 768px) {
							display: flex;
							flex-direction: column;
							margin: 0;

						}

						.htmltext {
							width: 28%;
							font-weight: 800;
							color: #6f6f6f;

							@media screen and (max-width: 768px) {
								width: 100%;
								font-weight: 800;
							}

							.fwbys {
								// font-size: 16px;
								color: #a7a7a7;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;

								@media screen and (max-width: 768px) {
									font-size: 12px;
								}
							}
						}

						display: flex;
						justify-content: space-between;
						margin: 2% 0;

						img {
							width: 70%;
							height: min-intrinsic;
							// flex-shrink: 0;

							@media screen and (max-width: 768px) {
								width: 100vw;
								height: 66.6666666vw;
							}
						}

						a {
							flex-grow: 1;
							text-align: left;
							padding: 2%;
						}

						.date {
							padding: 2% 0;
							flex-shrink: 0;
							align-self: flex-end;
						}
					}
				}
			}
		}
	}

	.jcspys {
		font-size: 2rem;
		background-color: rgba($color: #ff4d40, $alpha: .9);
		// margin: 0 10%;
		color: #FFFFFF;
		font-weight: 800;
		border-radius: 6px 6px 0 0;

		@media screen and (max-width: 768px) {
			font-size: 1rem;
			margin: 0 0%;
		}
	}

	.jcspys2 {
		font-size: 2rem;
		// background-color: rgba($color: #ff4d40, $alpha: .9);
		// margin: 0 10%;
		color: #484848;
		font-weight: 800;
		border-radius: 6px 6px 0 0;

		@media screen and (max-width: 768px) {
			font-size: 1rem;
			margin: 0 0%;
		}
	}

	.home {
		width: 60vw;
		margin: 0 19vw;

		.el-carousel {
			position: relative;
			left: -20vw;
			width: 100vw;

			.el-image {
				width: 100%;
				height: 100%;
			}
		}

		.intro {
			display: flex;
			width: 100%;

			@media screen and (max-width: 1200px) {
				display: contents;
			}

			.item {
				flex: 1;
				padding: 30px 5px;

				h2 {
					// font-size: 23px;
					color: rgba(80, 80, 80, 1);
					font-weight: bold;
					margin: 10% 0;
				}
			}

		}

		@media screen and (max-width: 768px) {
			.intro {
				// display: flex;
				width: 100%;

				.item {
					flex: 1;
					padding: 5px 5px;

					h2 {
						font-size: 23px;
						color: rgba(80, 80, 80, 1);
						font-weight: bold;
						margin: 3% 0;
					}

					img {
						width: 20%;
					}
				}

			}
		}

		.team {
			h1 {
				color: rgba(80, 80, 80, 1);
				font-size: 34px;
				font-weight: bold;
			}

			@media screen and (max-width: 768px) {
				h1 {
					color: rgba(80, 80, 80, 1);
					font-size: 16px;
					font-weight: bold;
				}
			}

			.el-button {
				background-color: #fafafa;
				border-color: #f50;
				color: #f50;

				&:hover {
					background-color: #f50;
					color: #FFFFFF;
				}
			}

			::v-deep .items {
				// display: flex;
				position: relative;

				.item {
					// flex: 1;
					width: 25%;
					padding: 30px;

					@media screen and (max-width: 1200px) {
						width: 40%;
						padding: 10px;
					}

					img {
						width: 176px;
						height: 176px;
						border-radius: 88px;
						border: 1px solid #eee;

						@media screen and (max-width: 1200px) {
							width: 80px;
							height: 80px;
						}
					}

					h2 {
						font-size: 23px;
						margin: 20px;
					}

					p {
						color: rgba(166, 166, 166, 1);
						font-size: 20px;
					}

					.el-button {
						font-size: 20px;
					}
				}

				.prev svg {
					transform: translateX(-200%);

					@media screen and (max-width: 1200px) {
						transform: translateX(0%);
					}

					background-color: #e0e0e06e;
					border-radius: 25px;
					width: 50px;
					height: 50px;

					path {
						fill: #fff;
					}
				}

				.next svg {

					transform: translateX(200%);

					@media screen and (max-width: 1200px) {
						transform: translateX(0%);
					}

					background-color: #e0e0e06e;
					border-radius: 25px;
					width: 50px;
					height: 50px;

					path {
						fill: #fff;
					}
				}
			}
		}

		.member {
			h1 {
				font-size: 25px;
				color: #6E666D;
				margin-top: 30px;
			}

			.item {
				display: flex;
				padding: 30px;

				.personalImage {
					width: 489px;
					height: 278px;

					@media screen and (max-width: 1200px) {
						width: 60%;
						height: 278px;
					}

					object-fit: cover;
					object-position: top;
					border-radius: 10px;
				}

				.rightORleft {
					padding: 30px;

					@media screen and (max-width: 1200px) {
						padding: 10px;

						.h2,
						h2 {
							font-size: 1.5rem;
						}
					}
				}
			}

		}

		/* 分布图*/
		#china_map_box {
			height: 1000px;
			/* position: relative; */
		}

		#china_map_box #china_map {
			height: 1000px;
		}

		#china_map_box .china_map_logo {
			position: absolute;
			top: 0;
			left: 10px;
			width: 45px;
		}

		.toTop {
			// width: 150px;
			// height: 50px;
			background-color: rgba($color: #f50, $alpha: 1);
			padding: 10px;
			border-radius: 10px;
			color: #FFFFFF;
			position: fixed;
			font-size: 16px;
			cursor: pointer;
			bottom: 5%;
			right: 2%;
		}
	}

	h1 {
		color: rgba(80, 80, 80, 1);
		font-size: 34px;
		font-weight: bold;
		margin-top: 50px;
	}

	@media screen and (max-width: 1300px) {
		.home {
			width: 100%;
			margin: 0;

			.el-carousel {
				left: 0;
			}
		}
	}

	.my_timeline_prev,
	.my_timeline_next {
		float: left;
		display: inline-block;
		background-color: #fff;
		cursor: pointer;
	}

	.my_timeline_prev {
		width: 200px;
		float: left;
	}

	.my_timeline_next {
		width: 34px;
		margin-left: -22px;
	}

	.ul_box {
		width: 900px;
		height: 60px;
		display: inline-block;
		float: left;
		margin-top: 2px;
		overflow: hidden;
	}

	.my_timeline_item {
		display: inline-block;
		width: 220px;
	}

	.my_timeline_node {
		box-sizing: border-box;
		border-radius: 50%;
		cursor: pointer;
	}

	.my_timeline_node.active {
		background-color: #fff !important;
		border: 6px solid #f68720;
	}

	.my_timeline_item_line {
		width: 100%;
		height: 10px;
		margin: -14px 0 0 28px;
		border-top: 2px solid #E4E7ED;
		border-left: none;
	}

	.my_timeline_item_content {
		margin: 10px 0 0 -10px;
	}
</style>
