<template>
  <div class="login"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    >
    <el-tabs v-if="curPage=='login'" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="密码登录" name="first">
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"  class="demo-ruleForm">
          <el-form-item label="" prop="username">
            <el-input v-model="ruleForm.username" autocomplete="off" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="密码"  @keyup.enter.native="submitForm"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="info" plain @click="submitForm">登录</el-button>
          </el-form-item>
        </el-form>
        <div class="login_pane1_bottom">
          <el-checkbox v-model="checked">下次自动登录</el-checkbox>
          <div>
            <span @click="curPage='forget'">忘记密码</span>
            |
            <!-- <span>注册</span> -->
            <span @click="curPage='register'">注册</span>
          </div>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="短信登录" name="second">短信登录</el-tab-pane> -->
    </el-tabs>
    <span class="close" @click="close">×</span>

    <div class="forget" v-if="curPage=='forget'">
      <el-form :model="forgetForm" status-icon :rules="forgetFormRules" ref="forgetForm">
        <el-form-item label="" prop="phone">
          <el-input v-model="forgetForm.phone" autocomplete="off" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item label="" prop="verifyCode" class="verify_code">
          <el-input placeholder="请输入验证码" v-model="forgetForm.verifyCode">
            <el-button slot="append" @click="sendCode" v-if="isSend">发送</el-button>
            <template slot="append" v-else>{{countdown}}</template>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input type="password" v-model="forgetForm.password" autocomplete="off" placeholder="请输入新的密码"></el-input>
        </el-form-item>
        <el-form-item label="" prop="password2">
          <el-input type="password" v-model="forgetForm.password2" autocomplete="off" placeholder="请再次输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="info" plain @click="submitForgetForm">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
    <span class="back" @click="curPage = 'login'" v-if="curPage!='login'">←</span>

    <div class="register" v-if="curPage=='register'">
      <el-form :model="registerForm" status-icon :rules="registerFormRules" ref="registerForm">
        <el-form-item label="" prop="name">
          <el-input v-model="registerForm.name" autocomplete="off" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="" prop="phone">
          <el-input v-model="registerForm.phone" autocomplete="off" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item label="" prop="code" class="verify_code">
          <el-input placeholder="请输入验证码" v-model="registerForm.verifyCode">
            <el-button slot="append" @click="sendCode" v-if="isSend">发送</el-button>
            <template slot="append" v-else>{{countdown}}</template>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input type="password" v-model="registerForm.password" autocomplete="off" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="" prop="password2">
          <el-input type="password" v-model="registerForm.password2" autocomplete="off" placeholder="请再次输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="info" plain @click="submitRegisterForm">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    var validateTel = (rule, value, callback)=>{
      let reg = /^1(3|4|5|6|7|8)\d{9}$/
      if (value == "") {
        callback(new Error("请输入手机号"));
      } else if (!reg.test(value)) {//引入methods中封装的检查手机格式的方法
        callback(new Error("请输入正确的手机号!"));
      } else {
        callback();
      }
    };

    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.forgetForm.password2 !== '') {
          this.$refs.forgetForm.validateField('password2');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value == this.forgetForm.password || value == this.registerForm.password) {
        callback();
      } else {
        callback(new Error('两次输入密码不一致!'));
      }
    };


    return {
      isSend:true,
      countdown:0,
      curPage:"login",
      activeName: 'first',
      loading:false,
      ruleForm: {
        username: '',
        password: ''
      },
      forgetForm:{
        phone:'',
        verifyCode:'',
        password:'',
        password2:''
      },
      registerForm:{
        verifyCode:'',
        name:'',
        password:'',
        password2:'',
        phone:''
      },
      rules: {
        username: [
          { validator: validateTel, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
        ]
      },
      forgetFormRules:{
        phone:[
          { validator: validateTel, trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入6位验证码', trigger: 'blur' }
        ],
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        password2: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      registerFormRules:{
        verifyCode:[
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入6位验证码', trigger: 'blur' }
        ],
        name:[
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 1, max: 32, message: '', trigger: 'blur' }
        ],
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        password2: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        phone:[
          { validator: validateTel, trigger: 'blur' }
        ]
      },
      checked:true
      // // 这是登录表单的数据绑定对象
      // loginForm: {
      //   username: 'admin',
      //   password: '123456'
      // },
      // // 这是表单的验证规则对象
      // loginFormRules: {
      //   // 验证用户名是否合法
      //   username: [{
      //     required: true,
      //     message: '请输入登录名称',
      //     trigger: 'blur'
      //   },
      //   {
      //     min: 3,
      //     max: 10,
      //     message: '长度在 3 到 10 个字符',
      //     trigger: 'blur'
      //   }
      //   ],
      //   // 验证密码是否合法
      //   password: [{
      //     required: true,
      //     message: '请输入登录密码',
      //     trigger: 'blur'
      //   },
      //   {
      //     min: 6,
      //     max: 15,
      //     message: '长度在 6 到 15 个字符',
      //     trigger: 'blur'
      //   }
      //   ]
      // }
    }
  },
  methods: {
    handleClick(tab, event) {
            console.log(tab, event);
    },
    close(){
      this.$emit('close')
    },


    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // config: {url: "/system/sendCode", method: "get", headers: {…}, params: {…}, baseURL: "http://192.168.1.8:8080", …}
    // data:
      // code: 200
      // data: null
      // msg: "操作成功"
      // __proto__: Object
    // headers: {content-type: "application/json"}
    // request: XMLHttpRequest {onreadystatechange: ƒ, readyState: 4, timeout: 0, withCredentials: false, upload: XMLHttpRequestUpload, …}
    // status: 200
    // statusText: ""
    async sendCode(){
      let phone = ''
      if(this.curPage == 'register'){
        phone = this.registerForm.phone
      }else{
        phone = this.forgetForm.phone
      }
      const { data } = await this.$http.get('/system/sendCode', {params:{phone:phone}})
      console.log(data)
      if(data.code == 200 && data.msg == "操作成功"){
        this.isSend = false
        this.countdown = 60
        let itv = setInterval(()=>{
          if(this.countdown > 0){
            this.countdown --
          }else{
            clearInterval(itv)
            this.isSend = true
          }
        },1000)
      }else{
        this.$message.error("发送失败")
      }
    },

    // code: 200
    // data:
      // age: null
      // gxqm: null
      // height: null
      // imgurl: null
      // jfdj: 2
      // lrrq: "2020-11-15 19:27:06"
      // name: "ty8888"
      // phone: "13113652094"
      // sex: 1
      // type: 0
      // weight: null
      // xgr: null
      // xgrq: "2020-11-15 19:27:06"
      // xybh: "TY8888"
      // yhdj: 0
    // msg: "登录成功"
    // token: "4c0dad87e0448ed8dc39a0f17fc25f5d"
    submitForm () {
      this.loading = true
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) return
        let formData = this.$qs.stringify(this.ruleForm)
        const { data } = await this.$http.post('/official/login/doLogin', formData)
        if(data.msg == "登录成功"){
          this.$message.success(data.msg)
          if(this.checked){
            window.localStorage.setItem('token', data.token)
            window.localStorage.setItem('username', data.data.name)
          }else{
            window.sessionStorage.setItem('token', data.token)
            window.sessionStorage.setItem('username', data.data.name)
          }
          this.$router.go(0)
        }else{
          return this.$message.error(data.msg)
        }
      })
      this.loading = false
    },

    // data:
    //   code: 200
    //   data: null
    //   msg: "注册成功"

      // code: 700
      // data: null
      // msg: "用户已存在"

      // code: 500
      // msg: "验证码无效"
    submitRegisterForm(){
      this.loading = true
      this.$refs.registerForm.validate(async valid => {
        if (!valid) return
        let obj = this.registerForm
        delete obj.password2
        let formData = this.$qs.stringify(obj)
        const {data} = await this.$http.post('/official/user/register', formData)
        if(data.code == 200 && data.msg == "注册成功"){
          this.$alert('注册成功，返回登录', '注册成功', {
            confirmButtonText: '确定',
            callback: () => {
              this.curPage = 'login'
            }
          });
        }else if(data.code == 700 && data.msg == "用户已存在"){
          this.$alert('用户已存在，返回登录', '注册失败', {
            confirmButtonText: '确定',
            callback: () => {
              this.curPage = 'login'
            }
          });
        }else if(data.code == 500 && data.msg == "验证码无效"){
          this.$alert('验证码无效', '注册失败', {
            confirmButtonText: '确定',
          });
        }
      })
      this.loading = false
    },
    // data:
      // code: 200
      // data: null
      // msg: "操作成功"

      // code: 500
      // msg: "验证码无效"
    submitForgetForm(){
      this.loading = true
      this.$refs.forgetForm.validate(async valid => {
        if (!valid) return
        let obj = this.forgetForm
        delete obj.password2
        let formData = this.$qs.stringify(obj)
        const {data} = await this.$http.post('/official/user/modifyPassword', formData)
        console.log(data)
        if(data.code == 200 && data.msg == "操作成功"){
          this.$alert('修改成功，返回登录', '修改结果', {
            confirmButtonText: '确定',
            callback: () => {
              this.curPage = 'login'
            }
          });
        }else if(data.code == 500 && data.msg == "验证码无效"){
          this.$alert('验证码无效', '注册失败', {
            confirmButtonText: '确定',
          });
        }
      })
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.login{
  position: fixed;
  left: 50vw;
  top: 40vh;
  transform: translate(-50%,-50%);
  background-color: #fff;
  width: 410px;
  // height: 380px;
  padding:0px 30px;
  // padding-top: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  z-index: 999;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  border: 1px solid #ebeef5;

  ::v-deep .el-tabs{
    // background-color: yellow;
    .el-tabs__header{
      margin: 0;
    }
    .el-tabs__nav-scroll{
          height: 75px;
        .el-tabs__nav.is-top{
          height: 100%;
          line-height: 75px;
          .el-tabs__item{
            color: #a1a1a1;
          }
          .el-tabs__item.is-active{
            color: #2b2b2b;
          }
        }
        .el-tabs__active-bar.is-top{
          background-color: #2b2b2b;
        }
     }
  }

  ::v-deep .el-form{
    margin-top: 45px;
    input{
      background-color: #F4F4F4;
      // &:active{
      //   border-color: yellow;
      // }
      &:focus{
        border-color: #a1a1a1;
      }
      .el-input__inner{
      }
    }
    .el-button{
      width: 100%;
    }
  }


  ::v-deep .login_pane1_bottom{
    display: flex;
    justify-content: space-between;
    color: #a1a1a1;
    margin-bottom: 20px;
    span{
      cursor: pointer;
    }
    .el-checkbox__inner{
      border-color: #ffea3f;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
      background-color: #ffea3f;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label{
          color: #606266;
    }
  }

  .back{
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
  }
  .close{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  .verify_code{
    .el-button{
      margin: 0;
    }
  }

}



</style>
