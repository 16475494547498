<template>
  <div class="header">
    <div class="footer" v-if="windowWidth > 1200">
      <div class="section1">
        <h3>关注我们</h3>
        <router-link to="/share/download"><i class="iconfont icon-wechat"></i></router-link>
      </div>
      <div class="section2">
        <div class="section2_left">
          <div class="section2_left_item" v-for="(item,index) in section2_data" :key="index">
            <router-link :to="item.path"><span>{{item.title}}</span></router-link>
          </div>
        </div>
        <img src="https://abc.xnrun.com/image/default/F9492B6E03CE469CBEA17A65F846E7B5-6-2.png" alt="">
      </div>

      <div class="section3">
        <p><a target="_blank" href="https://beian.miit.gov.cn"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img
              src="https://abc.xnrun.com/image/default/D8C7B1F15AE64973A0FEBD369957C44C-6-2.png" style="float:left;" />
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤公网安备
              44010602008673号</p>
          </a>
          <a target="_blank" href="https://beian.miit.gov.cn"
              style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
              <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤ICP备2020087360号</p>
            </a>
        </p>
        <p>
          Copyright © 2018 - 2020 广州小聂跑法科技有限公司 版权所有
        </p>
      </div>
    </div>

    <b-navbar v-else toggleable="lg" class="mobile">
      <b-navbar-nav>
        <b-nav-item-dropdown text="关注我们">
          <b-dropdown-item href="/share/download">微信</b-dropdown-item>
          <!-- <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item> -->
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- <b-navbar-nav>
        <b-nav-item-dropdown text="关于我们">
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav> -->
      <!-- <b-navbar-nav>
        <b-nav-item-dropdown text="联系我们">
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-nav-item-dropdown text="法律信息">
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav> -->
      <div class="bottom">
        <p>
          <span>法律声明</span>
          <span><a herf="https://ccc.xnrun.com/Wisdom_life/www/share/ysxyindex.html">用户协议</a></span>
          <span>网站地图</span>
          <span>粤公网安备 44010602008673号</span>
        </p>
        <p>
          Copyright © 2018 - 2020 广州小聂跑法科技有限公司 版权所有
        </p>
      </div>
    </b-navbar>
  </div>


</template>

<script>
  export default {
    data() {
      return {
        visible: false,
        activeIndex: window.sessionStorage.menu,
        routeType: window.sessionStorage.routeType,
        isLogin: false,
        isVip: false,
        menu: [{
            title: '首页',
            path: '/'
          },
          {
            title: '新闻',
            path: '/Journalism'
          },
          {
            title: '荣誉榜',
            path: '/photowall'
          },
          {
            title: 'APP下载',
            path: '/download'
          },
          {
            title: '活动',
            path: '/course'
          },
          {
            title: '基地',
            path: '/base'
          },
          {
            title: '关于我们',
            path: '/about'
          }
        ],
        showLogin: false,

        windowWidth: window.screen.width,
        section2_data: [{
            title: '关于我们',
            path: '/about'
          },
          {
            title: '用户服务协议',
            path: '/protocol?type=service'
          },
          {
            title: '用户隐私协议',
            path: '/protocol?type=privacy'
          },

        ]
        // join:[
        // 	"关于我们",'联系我们'
        // ],
        // contact:[
        // ],
        // law:[
        // ]

      }
    },

    computed: {
      username() {
        return window.localStorage.getItem('username') || window.sessionStorage.getItem('username')
      },
      token() {
        return window.localStorage.getItem('token') || window.sessionStorage.getItem('token')
      },

    },
    created() {
      console.log(window.screen.width)
    },
    methods: {
      logout() {
        this.$confirm('确定要退出?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const {
            data
          } = await this.$http.post('/official/login/logout')
          if (data.code == 200 && data.msg == "用户退出成功") {
            window.localStorage.removeItem('username')
            window.localStorage.removeItem('token')
            this.$router.go(0)
          }

        });
      }

    },
    mounted() {

    }
  }
</script>

<style scoped lang="scss">
  @import url(https://at.alicdn.com/t/font_2234941_i0czansbrlj.css);

  .header {
    margin-top: 3%;

    .footer {
      // width: 1200px;
      // margin: 0 auto;
      padding: 0 20%;

      .section1 {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f2f3f5;
        text-align: left;
        color: #5f6464;
        font-size: 20px;

        h3,
        i {
          margin-right: 30px;
        }

        i {
          font-size: 28px;
          cursor: pointer;

          &:hover {
            color: #1AAD19;
            transition: .3s;
          }
        }

        a {
          text-decoration: none;
          color: #5f6464;
        }
      }

      .section2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 40px 0;

        .section2_left {
          display: flex;

          .section2_left_item {
            display: flex;
            flex-direction: column;
            width: 216px;
            text-align: left;

            span {
              font-size: 16px;
              margin-bottom: 18px;

              &:first-child {
                font-size: 20px;
                margin-bottom: 30px;
              }
            }

            a {
              text-decoration: none;
              color: #5f6464;
            }
          }
        }

        img {
          width: 212px;
          // height: 28px;
          margin-right: 75px;
        }
      }

      .section3 {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        p {
          span {
            margin-right: 20px;
          }
        }
      }
    }

    ::v-deep .navbar-nav {
      width: 100%;
    }

    ::v-deep .nav-link.dropdown-toggle {
      display: flex;
      justify-content: space-between;
    }

    ::v-deep .navbar-light .navbar-nav .nav-link {
      color: #5f6464;
    }

    ::v-deep .navbar.navbar-light.navbar-expand-lg {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .bottom {
        text-align: left;
        font-size: 12px;

        p {
          margin: 16px 0;
        }

        span {
          margin-right: 10px;
        }
      }
    }

    .mobile {}
  }
</style>
